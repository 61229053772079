import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import { H1 } from "../styles/typography"
import { main } from "../styles/theme"
// import { flexCenter, gridLayout } from "../styles/mixins"

const HeadingWrapper = styled.div`
  position: relative;
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const Space = styled.div`
  padding-bottom: 46px;

  @media ${main.breakpoints.large} {
    padding-bottom: 150px;
  }
`

// const DocumentButtonWrapper = styled.div`
//   position: absolute;
//   ${gridLayout}
//   top: 33%;

//   z-index: 10;

//   @media ${main.breakpoints.medium} {
//     padding: 0 30px;
//   }
//   @media ${main.breakpoints.large} {
//   }
// `

// const DocumentButton = styled(props => (
//   <AniLink cover bg="#C4C4C4" {...props} />
// ))`
//   ${flexCenter};
//   display: grid;
//   grid-column: 12 / span 12;
//   font-size: 1rem;
//   column-gap: 40px;

//   position: relative;
//   border-radius: 50%;
//   width: 100%;
//   height: auto;
//   padding-top: 100%;

//   &::before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     background-color: ${({ theme }) => theme.colors.black};
//     transition: transform 0.2s ease;
//   }

//   &::after {
//     color: ${({ theme }) => theme.colors.white};
//     content: "Köpanmälan";
//     text-align: center;
//     width: 100%;
//     position: absolute;
//   }

//   &:hover {
//     &::before {
//       transform: scale(1.125);
//       transition: transform 0.4s ease;
//     }
//   }

//   @media ${main.breakpoints.small} {
//     grid-column: 10 / span 4;
//   }
//   @media ${main.breakpoints.medium} {
//     grid-column: 12 / span 4;
//   }

//   @media ${main.breakpoints.large} {
//     font-size: 1.25rem;
//     grid-column: 12 / span 4;
//   }

//   @media ${main.breakpoints.xlarge} {
//     grid-column: 10 / span 2;
//     font-size: 1.375rem;
//   }
// `

const OmOss = () => (
  <Layout has4dContactInfo hasBuyButton={false}>
    <Seo title="Kontakt" />
    <HeadingWrapper>
      <H1 size="large">Kontakt</H1>
      {/* <DocumentButtonWrapper>
        <DocumentButton to="/kopanmalan"></DocumentButton>
      </DocumentButtonWrapper> */}
    </HeadingWrapper>
    <StaticImage
      src="../images/porthuset-terrass-cropped.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Om Smedjan och 4D gruppen"
      style={{ marginTop: `2.45rem` }}
    />
    <Space />
  </Layout>
)

export default OmOss
